<template>
  <div>
    <div class="grid-x grid-margin-x">
      <div class="cell small-6 medium-4 large-3">
        <data-card
          v-bind:card-id="'storeDataCards'"
          v-bind:theme="'green'"
          v-bind:name="'shop.statistics.sales'"
          v-bind:value="statistics.sales || 0"
        >
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card
          v-bind:card-id="'storeDataCards'"
          v-bind:theme="'yellow'"
          v-bind:name="'shop.statistics.visits'"
          v-bind:value="statistics.sessions || 0"
        >
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card
          v-bind:card-id="'storeDataCards'"
          v-bind:theme="'red'"
          v-bind:name="'shop.statistics.prospects'"
          v-bind:value="statistics.leads || 0"
        >
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card
          v-bind:card-id="'storeDataCards'"
          v-bind:theme="'green'"
          v-bind:name="'shop.statistics.full-carts'"
          v-bind:value="statistics.leads || 0"
        >
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card
          v-bind:card-id="'storeDataCards'"
          v-bind:theme="'green'"
          v-bind:name="'shop.statistics.total-amount'"
          v-bind:value="formatNumber(statistics.salesAmount || 0)"
        >
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card
          v-bind:card-id="'storeDataCards'"
          v-bind:theme="'yellow'"
          v-bind:name="'shop.statistics.profits'"
          v-bind:value="formatNumber(statistics.profits || 0)"
        >
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card
          v-bind:card-id="'storeDataCards'"
          v-bind:theme="'red'"
          v-bind:name="'shop.statistics.sold-products'"
          v-bind:value="statistics.cantidad || 0"
        >
          <message-icon />
        </data-card>
      </div>
      <div class="cell small-6 medium-4 large-3">
        <data-card
          v-bind:card-id="'storeDataCards'"
          v-bind:theme="'green'"
          v-bind:name="'shop.statistics.qr-visits'"
          v-bind:value="statistics.visitorsQr || 0"
        >
          <message-icon />
        </data-card>
      </div>
    </div>
    <div class="cell small-12 medium-12">
      <data-chart
        ref="channels"
        v-bind:name="'shop.statistics.sales-channel'"
        v-bind:chart-id="'channels'"
        v-bind:chart-data="channelData"
        v-bind:type="'pie'"
        v-bind:type-loader="'piechart'"
        v-bind:activeFilterDate="true"
        v-on:change="chartRangeChanged"/>
    </div>
    <div class="grid-x grid-margin-x">
      <div class="cell small-12 medium-12">
        <data-table
          v-bind:translatable="false"
          v-bind:formats="tableAgentsKpi.formats"
          v-bind:name="'shop.statistics.title-table-products'"
          v-bind:table-id="'products'"
          v-bind:headers="tableAgentsKpi.headers"
          v-bind:rows="products"/>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapActions, mapGetters, mapState } from 'vuex';
import DataCard from '@/components/DataCard.vue';
import DataTable from '@/components/DataTable.vue';
import ChannelData from '@/graphql/queries/channels/channelById.gql';
import executeQuery from '@/utils/gql-api';
import DataChart from '@/components/DataChart.vue';

export default {
  props: {
    filters: {
      required: true,
    },
  },
  components: {
    DataChart,
    DataTable,
    DataCard,
  },
  data() {
    return {
      tableAgentsKpi: {
        headers: [
          this.$t('shop.product.title'),
          this.$t('shop.statistics.campaign'),
        ],
        formats: [
          false,
        ],
      },
      campaignId: null,
      labels: [],
      series: [],
      chartOptions: {},
      json_meta: [
        [{
          key: 'charset',
          value: 'utf-8',
        }],
      ],
      channelData: {
        series: [],
      },
      campaigns: [],
    };
  },
  computed: {
    ...mapState({
      statistics: state => state.shop.statistics,
      products: state => state.product.items,
      channelsStatics: state => state.shop.channelsStatics,
    }),
    ...mapGetters({
      company: 'shared/getCurrentCompany',
      isOwner: 'shared/isOwner',
      isSuper: 'shared/isSuper',
    }),
    json_fields() {
      return {
        Step: 'name',
        Total: 'value',
      };
    },
    titleExcel() {
      return `Reporte de ${this.getCampaign(this.campaignId)}`;
    },
  },
  watch: {
    filters(newValue) {
      const { campaignId } = newValue || {};
      this.campaignId = campaignId;
      this.loadStatistics();
    },
  },
  mounted() {
    this.campaignId = this.filters.campaignId;
    this.loadStatistics();
  },
  methods: {
    ...mapActions({
      getStatistics: 'shop/getStatistics',
      getChannels: 'shop/getChannels',
      getProductos: 'product/getItemsOutOfStock',
    }),
    async loadStatistics() {
      this.$store.commit('dashboard/enableLoader', 'storeDataCards');
      let campaigns = [this.campaignId];
      if (!this.campaignId) {
        campaigns = this.company.campaigns.map(({ id }) => id);
      }
      this.campaigns = campaigns;
      await this.getStatistics({ campaigns, ...this.filters });
      if (this.campaignId) {
        this.getProductos(this.campaignId);
      }
      this.$store.commit('dashboard/disableLoader', 'storeDataCards');
      await this.channels();
    },
    async channels() {
      this.channelData = {
        series: [],
      };
      this.channelsStatics.forEach(async (channelId) => {
        const channelData = await this.getChannelById(channelId);
        const infoChannel = this.channelData.series.find(
          dataChannel => dataChannel.id === channelId,
        );
        if (!infoChannel) {
          this.channelData.series.push({
            name: channelData,
            y: 1,
            children: [],
            id: channelId,
          });
        } else {
          infoChannel.y += 1;
        }
      });
    },
    async getChannelById(id) {
      const data = await this.fetchData('channelById', ChannelData, { id });
      return data[0].channelType.name;
    },
    async fetchData(id, query, variables = {}) {
      const data = await executeQuery(id, query, variables);
      return data;
    },
    async loadChannels(filters) {
      await this.getChannels({ campaigns: this.campaigns, ...filters });
      await this.channels();
    },
    chartRangeChanged(chartId, from, to) {
      if (from !== null && to !== null) {
        const startDate = this.$moment(from)
          .format('YYYY-MM-DD');
        const endDate = this.$moment(to)
          .format('YYYY-MM-DD');

        const filters = {
          startDate,
          endDate,
        };
        this.loadChannels(filters);
        switch (chartId) {
          case 'conversationsByChannel':
            this.loadConversationsByChannel(filters);
            break;
          case 'conversationsReceivedVsAttended':
            this.loadConversationsReceivedVsAttended(filters);
            break;
          case 'conversationsByCategory':
            this.loadConversationsByCategory(filters);
            break;
          case 'conversationsStatuses':
            this.loadConversationsStatuses(filters);
            break;
          default:
            break;
        }
      }
    },
    getCampaign(idCampaign) {
      if (!idCampaign) {
        return 'Todas';
      }
      const campaign = this.company.campaigns.find(({ id }) => id === idCampaign);
      return campaign ? campaign.name : '';
    },
    formatNumber(total) {
      return numeral(total).format('$0,0.00');
    },
  },
};

</script>

<style scoped lang="scss">

  .empty-rows {
    background-color: white;
  }

.download {
  margin-bottom: 10px;
}

</style>
